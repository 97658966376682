import { PropTypes } from "prop-types";

import useDeviceDetect from "../../hooks/useDeviceDetect";
import RichTextContentful from "../RichTextContentful";

import "./saleBlock/styles.scss";

function SaleBlock({ title, subtitle, content, backgroundColors, textColors }) {
  const isMobile = useDeviceDetect();

  const background = isMobile
    ? backgroundColors?.mobile || "#FBF7EC"
    : backgroundColors?.desktop || "FBF7EC";

  const color = isMobile ? textColors?.mobile || "#000" : textColors?.desktop || "#000";

  if (!title) {
    return null;
  }

  return (
    <div className="sale-block-wrapper" style={{ backgroundColor: background, color }}>
      <h3 className="h3 h3--bold m-0">{title}</h3>
      {subtitle && <p className="text mb-20">{subtitle}</p>}
      {content?.raw && (
        <RichTextContentful input={content.raw} prefixClassName="sale-block-wrapper__content" />
      )}
    </div>
  );
}

SaleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.shape({
    raw: PropTypes.string,
  }),
  backgroundColors: PropTypes.shape({
    mobile: PropTypes.string,
    desktop: PropTypes.string,
  }),
  textColors: PropTypes.shape({
    mobile: PropTypes.string,
    desktop: PropTypes.string,
  }),
};

export default SaleBlock;
